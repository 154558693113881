import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesMarciana: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Marciana guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_marc.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Marciana guide & review</h1>
          <h2>A guide & review for Marciana in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_marc.jpg"
          alt="Marciana"
        />
        <p>
          <strong>Marciana</strong> banner will be available from{' '}
          <strong>9/21 05:00 to 10/05 04:59 (UTC+9).</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="marciana" enablePopover />
        </div>
        <p>
          Claiming to be an exemplary teacher at M.M.R. Vocational School,{' '}
          <strong>
            Marciana is the only reliable B2 Healer aside from Blanc
          </strong>
          . While we may not be so sure what kind of example she is giving to
          her students, she teaches them that healing is important in most
          battles. Alongside a brief lifesteal and HP potency for two units on
          last bullet, as well as a teamwide heal when using Burst Skill,{' '}
          <strong>
            she offers a unique buff called Storage, which stores excess HP
            recovery by expanding the maximum HP limit (like a padding)
          </strong>
          . This technically increases the maximum durability of every unit,
          allowing them to survive critical attacks that would otherwise erase
          their existence.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          There is a noticeable difference between how Marciana's healing works
          versus other healers.{' '}
          <strong>
            She does not heal units on last bullet based on her HP, but instead
            provides lifesteal to everyone.
          </strong>{' '}
          This can be good or bad. On one hand, most attackers with consistent
          damage will definitely enjoy more healing from lifesteal as they can
          dish out a lot of damage, not to account the accompanying HP potency.
          Burst attackers like Maxwell and 2B may require precise timing to
          fully benefit from it, but with some manual gameplay, it may be
          manageable. On the other hand, supports and defenders unit whose ATK
          aren't buffed will have trouble benefitting from it. The amount of the
          lifesteal is quite low, so you need to pay extra attention to your
          units' HP all the time.
        </p>
        <p>
          Thankfully, she does heal the team every rotation when using Burst
          Skill (tied to Skill 2). This is similar to Pepper's Burst heal, which
          has been proven to be extremely useful. Her Burst Skill also provides
          a buff called Storage, which stores excess HP recovery by expanding
          the HP limit (like a container placed on top of MAX HP). This also
          synergizes with her Skill 2, allowing her team to expand their HP
          limit when they are at maximum HP.{' '}
          <strong>
            The synergy between Skill 2 and Burst Skill is similar to SMary's
            Burst skill alone!
          </strong>
        </p>
        <p>
          Last but not least, her Burst Skill also offers a slight DEF boost
          based on the caster's DEF. So far, this seems to be underwhelming in
          most contexts, so I will assume that the final line simply doesn't
          exist. Poof. Still, it reduces the overall amount of damage your team
          takes, which may be the turning point in some awfully specific cases.
        </p>
        <h5>How Storage Works</h5>
        <p>
          Storage functions similarly to MAX HP, but there are several
          differences.{' '}
          <strong>
            MAX HP is indicated with the condensing of white bar in battles,
            while Storage is indicated with a green bar instead (placed at the
            end)
          </strong>
          . Dark Green shows that the Storage is empty, while Light Green shows
          that the Storage is filled by that amount. Light Green is consumed
          first before White, and White must be fully filled first before Light
          Green appears. In other words, it is just a container that expands HP
          limit but is not part of HP itself. Likewise, excess HP stored by MAX
          HP and Storage buffs (indicated as Light Green) disappears at the end
          of their duration.
        </p>
        <Row xs={1} xl={2} xxl={2} className="comparison">
          <Col>
            <div className="box">
              <StaticImage
                src="../../../images/nikke/generic/marc_1.jpg"
                alt="Marciana"
              />
              <p>
                We can see that Storage is filled here. Scarlet and A2 damage
                themselves all the time. See how Storage is being consumed.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <StaticImage
                src="../../../images/nikke/generic/marc_2.jpg"
                alt="Marciana"
              />
              <p>After Gravedigger attacks, Storage is now depleted.</p>
            </div>
          </Col>
        </Row>
        <p>
          Thanks to <strong>MizoreRainy</strong> for providing these
          screenshots!
        </p>
        <h5>Max HP vs Storage</h5>
        <p>Here are some core differences between MAX HP and Storage:</p>
        <ul>
          <li>
            MAX HP buffs increase the effects of skills that rely on caster's
            MAX HP, such as 2B's Skill 2 and most healing skills.{' '}
            <strong>Meanwhile, Storage does NOT.</strong>
          </li>
          <li>
            Storage acts as an extra container placed on top of MAX HP, so it is
            NOT considered part of HP itself. Even if Soline with Storage gets
            hit, as long as the damage does not chew into her HP (and drain
            Storage entirely), she will still have full HP. This is one strength
            it has over MAX HP.
          </li>
        </ul>
        <Row xs={1} xl={2} xxl={2} className="comparison">
          <Col>
            <div className="box">
              <StaticImage
                src="../../../images/nikke/generic/marc_3.jpg"
                alt="Marciana"
              />
              <p>
                Here is a picture of Soline buffed with Storage. The Storage
                here is already consumed a little (check next picture).
              </p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <StaticImage
                src="../../../images/nikke/generic/marc_4.jpg"
                alt="Marciana"
              />
              <p>
                As you can see, her full HP skills are still present. Pay close
                attention to her HP values!
              </p>
            </div>
          </Col>
        </Row>
        <ul>
          <li>
            <strong>That also means that Storage does NOT expand MAX HP</strong>
            . Therefore, self-harm skills like A2, Scarlet, and Guillotine will
            drain the same HP per second, even if Storage is full and their
            current HP is maxed. It will also NOT raise their skill activation
            threshold.
          </li>
          <li>
            For instance, if your Scarlet has 1,000,000 current & MAX HP, and
            Storage MAX value is 200,000, Scarlet will have an effective HP of
            1,200,000. However, she will only drain 40,000 (4%) HP per second
            until storage is depleted instead of 48,000 because Storage HP is
            not considered.
          </li>
          <li>
            <strong>
              Unlike MAX HP, triggering Storage does NOT naturally heal affected
              Nikkes
            </strong>
            . Marciana utilizes the heal from Skill 2 to fill her Storage.
          </li>
          <li>
            Marciana's Storage max amount depends on her MAX HP, but most MAX HP
            buffs are dependent on the target unit's MAX HP instead (except for
            Mast and SMary).
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Performance Analysis" />
        <p>
          <strong>
            For beginner players without an extensive roster, Marciana is worth
            deploying in stages where healing is often demanded, allowing you to
            clear contents with ease as you unlock more units
          </strong>
          . Boss stages, for example, almost always requires some sustain,
          whether in the form of heals or shields. Unless you possess stronger
          healers such as Pepper or SMary, or an overpowered character like
          Blanc, Marciana will remain valuable. You can keep using her until you
          obtain more powerful B2 to replace her, especially in Raids where you
          need to make 3 to 5 teams.
        </p>
        <p>
          <strong>
            For endgame players with an almost complete roster, Marciana will
            instead be seeing more usage in contents where damage-boosting B2
            aren't applicable, or where a third B3 is preferred but a B2 healer
            is needed.
          </strong>{' '}
          This problem mainly occurs in Raids and potentially Elysion Tower,
          which would be her primary battlefield. Unfortunately, the current B2
          that we usually use are simply too influential to replace, so in the
          worst case, she may not be seeing any usage at all. Nevertheless, she
          can still be used in other contents and still be flexible, especially
          if you do not have other healers.
        </p>
        <p>
          <strong> Don't expect much from her in Arena.</strong> She can be
          situationally useful, but she is not by any means a component of the
          current meta. She serves the same purpose as Noise and SMary,
          expanding each unit's HP pool and allowing them to withstand more
          damage. However, Noise's skills have more attractive values, and since
          cooldown isn't an issue in PvP, Noise is always superior. Moreover,
          the 10% lifesteal (that's at level 10 by the way) only sets in after 6
          seconds, and the value is too small to justify using her. Still,
          Marciana is a B2 healer, so she can be paired with a damage-boosting
          B1 or another B1 healer for more sustain. In summary, indeed, she is
          better than the average B2 for Arena, but she won't be replacing the
          existing meta units.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          <strong>Gear investments aren't necessary</strong>, but if you
          urgently need more heal, upgrade and OL her Armor for more healing.
          You can also upgrade her Head, then Shoes. As for the OL lines, you do
          not need to reroll them at all since her damage isn't that brilliant
          anyways. However, avoid Max Ammo lines as much as possible as they
          clash with her last bullet mechanics.
        </p>
        <h5>Skill Investments</h5>
        <p>
          As usual, skill investments aren't that needed for healers (except in
          the case of Arena, but she is just average there).
        </p>
        <p>
          Focus on upgrading her Skill 2 first as that will be her main source
          of heal alongside Skill 1. It's also more consistent than lifesteal
          because some Nikkes will have trouble benefitting from it. At the same
          time, units with high damage may benefit from Skill 1 more. Hence, at
          the end, it is recommended to upgrade both equally.
        </p>
        <p>
          Burst Skill is optional since it is only about increasing DEF, which
          is useless in most cases, and the maximum amount of Storage. Remember,
          Storage does NOT naturally heal the affected Nikkes, so if your team
          constantly takes damage and hovers around half HP, then Storage is
          completely useless.
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            In summary, upgrade her Skill 2 for more healing every rotation,
            then her Skill 1. Burst is optional. Recommended to go 3-5 / 3-5 /
            3.
          </p>
        </Alert>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Marciana"
        />
        <p>
          <strong>Resilience.</strong> Period. This will allow her to abuse her
          Skill 1 way more often. Bastion and Wingman are the opposite and
          should be avoided. Assault can be used for more hitrate, but why?
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Team Compositions" />
        <p>
          <strong>
            Marciana is a versatile B2 healer that can fit in many teams
          </strong>
          , especially the ones with one powerful B1 and three crucial B3.
          However, as a B2 healer with no CDR, you should always pair her with a
          B1 CDR unit, unless CDR isn't necessary. You can also pair her with a
          B2 CDR, but unless it is SHelm (whose Burst Skill is quite useless),
          it's better for you to use off-burst healers instead like SMary or
          Rapunzel.{' '}
          <strong>
            However, again, to maximize her potential, you are instead
            encouraged to build a 1-2-3-3-3 setup.
          </strong>
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            It is also generally not recommended to couple her with Max Ammo
            buffers as that will slow down the activation of Skill 1. Still, the
            loss is trivial, so it is not a major setback (contrary to SAnis).
            In fact, the best buffers like Liter will boost Max Ammo, and it is
            much better using her than avoiding her in story boss stages, for
            example.
          </p>
        </Alert>
        <p>
          Since her main focus would be in Raids, below are some example teams
          that we foresee. This section is targeted to endgame players who want
          to maximize their units' potential. Be informed that these may not be
          the best teams, but if you lack better units, you are always welcome
          to adopt these.{' '}
          <strong>
            Also, these teams use Privaty, but you do NOT need to use Privaty
            for Marciana to perform.
          </strong>
        </p>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The regular variant of this team uses Novel instead of Marciana, which
          lacks an AoE healer and tries to take advantage of Alice's self-heal
          as much as possible. If the boss does too much AoE damage, or if you
          wish to trade DPS with more sustain, this can be a good alternative.
          Marciana's skills synergize well with Priv's last bullet, allowing you
          to dish out as much damage as possible without worrying about dying.
          You can also bring three B3 with you as you no longer need to
          sacrifice one for a B1 healer.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team recycles the idea that underpins the first team by using
          different core DPS. In this example, SAnis and Privaty have a strong
          synergy, which not only boosts her own DPS but also Scarlet's thanks
          to "infinite ammo". At the same time, Marciana also enjoys Privaty's
          buffs and can keep the team alive longer. Non-Marciana variants of
          this team involve the use of Diesel, Sin, and possibly Quency. There
          is also a non-Privaty variant that exchanges Privaty for XRupee.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          Pushing story boss stages without Blanc? Marciana comes to the rescue.
          There is an alternative, Helm, but if you want to use all of your 3
          DPS at the same time or need all three B3 to perform, there is no
          healer better than Marciana. With Marciana, you can also use Liter,
          which is a vital story unit.
        </p>
        <SectionHeader title="Should You Pull" />
        <Alert variant="primary" className="alert-update">
          <p>
            Pull only if you are lacking a versatile healer, especially if you
            are starting the game. You may also pull her for flexibility in
            Raids, but <strong>one copy is more than enough.</strong>
          </p>
        </Alert>
        <p>
          Currently, we do not have a lot of B2 healer choices. We do have
          Folkwang, but she has a 40s CD and only heals certain units. We have
          Yuni, but her healing is just too small to make an impact. The last
          one is Blanc, but Blanc is always paired with Noir, so she is not as
          versatile. Marciana happily resolves all these problems. As a
          standalone B2 healer, she can fit in many teams. However, since we
          already have Pepper, SMary, and many other healers, she may be easily
          outclassed or not as useful as you initially thought. Furthermore, B2
          has tough competition. With Novel, Mast, Blanc, and CDR units (Dolla &
          SHelm) joining the fray, she may not even be used in Raids at all.
        </p>
        <p>
          Two new units are also headed our way, Tia and Naga, and their kits
          seem to be overpowered. You should definitely try to acquire them
          instead of Marciana. They may be the second Blanc & Noir, which
          require you to obtain both of them to fully utilize their potential,
          but they seem too good to miss. They also provide healing, cover
          recovery, and tons of DPS boost!
        </p>
        <p>
          <strong>
            Do be warned that, if they release a powerful unit that relies on
            full HP (like Brid & Soline) in the future, Marciana may start
            seeing more usage. &nbsp;
          </strong>
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Grants constant lifesteal</li>
                  <li>Provides increased HP limit through Storage</li>
                  <li>B2 Healer, which is rare and great for beginners</li>
                  <li>
                    Allows units who have full HP mechanics to take tiny damage
                    without losing buffs
                  </li>
                  <li>Versatile and can fit in many teams</li>
                  <li>
                    Last bullet mechanic allows Privaty to improve her healing
                    power
                  </li>
                  <li>
                    Will help in clearing Elysion Tower if healer is needed
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Healing capacity depends on the other units' damage output,
                    which can be good or bad (inconsistent)
                  </li>
                  <li>
                    Storage buff does not naturally heal, so it may be useless
                    at times
                  </li>
                  <li>
                    Despite being versatile, she competes with other B2 units
                    who provide way more value
                  </li>
                  <li>
                    Storage buff isn't considered MAX HP, so it does not make
                    skills based on MAX HP more effective
                  </li>
                  <li>
                    Last bullet mechanic discourages pairing with certain units
                    who buff Max Ammo
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (S)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMarciana;

export const Head: React.FC = () => (
  <Seo
    title="Marciana guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Marciana in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
